import Login from "./views/_Auth/Login";
import React from "react";


const LazyLogout = React.lazy(() => import('./views/_Auth/Logout'));
const LazyHome = React.lazy(() => import('./views/Home/Home'));
const LazyCalendrierConges = React.lazy(() => import('./views/Conges/Calendrier/CalendrierConges'));
const LazyTimeTrackingMe = React.lazy(() => import('./views/TimeTracking/TimeTrackingMe'));
const LazyTimeTrackingAll = React.lazy(() => import('./views/TimeTracking/TimeTrackingAll'));
const LazyProjetsAccueil = React.lazy(() => import('./views/SuiviProjets/Accueil/Accueil'));
const LazyProjetsDashboard = React.lazy(() => import('./views/SuiviProjets/Dashboard/DashBoard'));
const LazyProjetsListeProjets = React.lazy(() => import('./views/SuiviProjets/Projets/ListeProjets'));
const LazyProjetsDetailProjet = React.lazy(() => import('./views/SuiviProjets/Projets/DetailProjet'));
const LazyProjetsDetailProjetTempsPasse = React.lazy(() => import('./views/SuiviProjets/Projets/DetailProjetTempsPasse'));
const LazyDebug = React.lazy(() => import('./views/Debug/Debug'));
const LazyUsersList = React.lazy(() => import('./views/Users/UsersList'));
const LazyBitriseAppList = React.lazy(() => import('./views/Bitrise/BitriseAppList'));
const LazyLogsLIst = React.lazy(() => import('./views/Logs/LogsList'));

export interface RoutesInterface {
    path: string;
    element: React.ReactNode;
    index?: boolean;
    description?: string;
    children?: RoutesInterface[];
    role?: string;
    noRoleRedirectUri?: string;
}

export const unprotectedRoutes: RoutesInterface[] = [
    {
        path: 'login',
        description: 'Page de connexion',
        element: <Login/>,
    },
]

export const protectedRoutes: RoutesInterface[] = [
    {
        path: 'logout',
        description: 'Déconnexion',
        element: <LazyLogout/>,
    },
    {
        path: '/',
        description: 'Home',
        element: <LazyHome/>,
    },
    {
        path: '/conges',
        description: 'Congés',
        element: <LazyCalendrierConges/>,
    },
    {
        path: '/debug',
        description: 'Debug',
        role: 'ROLE_SUPER_ADMIN',
        element: <LazyDebug/>,
    },
    {
        path: '/timetracking/me',
        description: 'Time Tracking Individuel',
        element: <LazyTimeTrackingMe/>,
    },
    {
        path: '/timetracking/all',
        description: 'Time Tracking Tous',
        role: 'ROLE_ADMIN_RH',
        element: <LazyTimeTrackingAll/>,
    },
    {
        path: '/suiviprojets',
        description: 'Suivi Projets',
        role: 'ROLE_ADMIN_PROJETS',
        element: <LazyProjetsAccueil/>,
        children: [
            {
                path: '/suiviprojets/dashboard/:type',
                description: 'Dashboard',
                role: 'ROLE_ADMIN_PROJETS',
                element: <LazyProjetsDashboard/>,
            },
            {
                path: '/suiviprojets/listeprojets',
                description: 'Liste projets',
                role: 'ROLE_ADMIN_PROJETS',
                element: <LazyProjetsListeProjets/>,
            },
            {
                path: '/suiviprojets/projet/:id',
                description: 'Detail Projet',
                role: 'ROLE_ADMIN_PROJETS',
                element: <LazyProjetsDetailProjet/>,
            },
            {
                path: '/suiviprojets/projet-tp/:id',
                description: 'Detail Projet',
                role: 'ROLE_ADMIN_PROJETS',
                element: <LazyProjetsDetailProjetTempsPasse/>,
            },
        ],
    },
    {
        path: '/users',
        description: 'Liste des utilisateurs',
        role: 'ROLE_ADMIN_RH',
        element: <LazyUsersList/>,
    },
    {
        path: '/bitrise',
        description: 'Bitrise App Liste',
        element: <LazyBitriseAppList/>,
    },
    {
        path: '/logs',
        description: 'Lecture des logs',
        element: <LazyLogsLIst/>,
    },
];